<template>
  <div>
    <div
      :class="['container container--full-screen', { mobileScreen: isMobile }]"
    >
      <div class="page-manager">
        <ManagerTable v-if="currentStep.step === 1" />

        <TimeSlotDetail v-else :timeslot="currentStep.data" />

        <DialogAddTimeslot />
        <DialogAddTimeslotHand v-if="terminalSlug !== 'step'" />
        <StepDialogAddTimeslotHand v-else />
        <StepDialogEditTimeslot />
        <DialogTimeslotViewing />
        <DialogTimeslotWrong />
        <DialogEditTimeslot />
        <DialogDeleteTimeslot />
        <DialogAdminDeleteTimeslot />
        <DialogForceArrival />
        <DialogChangeTableColumn />
      </div>
    </div>
  </div>
</template>

<script>
import { GET_IS_MOBILE, GET_TERMINAL_CURRENT_ID_SLUG } from '@/store/actions'
import { GET_TIMESLOTS_CURRENT_STEP } from '@/views/manager/store/actions'
import { mapGetters } from 'vuex'
import DialogAddTimeslot from '@/views/manager/components/dialogs/dialog-add-timeslot/DialogAddTimeslot'
import DialogAddTimeslotHand from '@/views/manager/components/dialogs/dialog-add-timeslot-hand/DialogAddTimeslotHand'
import DialogAdminDeleteTimeslot from '@/views/manager/components/dialogs/dialog-delete-timeslot/DialogAdminDeleteTimeslot.vue'
import DialogChangeTableColumn from '@/views/manager/components/dialogs/dialog-change-table-column/DialogChangeTableColumn'
import DialogDeleteTimeslot from '@/views/manager/components/dialogs/dialog-delete-timeslot/DialogDeleteTimeslot'
import DialogEditTimeslot from '@/views/manager/components/dialogs/dialog-edit-timeslot/StepDialogEditTimeslot'
import DialogForceArrival from '@/views/manager/components/dialogs/dialog-force-arrival/DialogForceArrival.vue'
import DialogTimeslotViewing from '@/views/manager/components/dialogs/dialog-timeslot-viewing/DialogTimeslotViewing'
import DialogTimeslotWrong from '@/views/manager/components/dialogs/dialog-timeslot-wrong/DialogTimeslotWrong'
import ManagerTable from '@/views/manager/components/manager-table/ManagerTable'
import StepDialogAddTimeslotHand from '@/views/manager/components/dialogs/dialog-add-timeslot-hand/StepDialogAddTimeslotHand'
import StepDialogEditTimeslot from '@/views/manager/components/dialogs/dialog-edit-timeslot/DialogEditTimeslot'
import TimeSlotDetail from '@/views/manager/components/time-slot-detail/TimeSlotDetail'

export default {
  name: 'Manager',
  metaInfo() {
    return {
      meta: [
        {
          vmid: 'adaptivity',
          name: 'viewport',
          content: this.isManagerPage
            ? 'width=1200, initial-scale=1'
            : 'width=device-width, initial-scale=1',
        },
      ],
    }
  },
  components: {
    TimeSlotDetail,
    ManagerTable,
    DialogChangeTableColumn,
    DialogAdminDeleteTimeslot,
    DialogDeleteTimeslot,
    DialogEditTimeslot,
    StepDialogEditTimeslot,
    DialogForceArrival,
    DialogTimeslotWrong,
    DialogTimeslotViewing,
    DialogAddTimeslotHand,
    StepDialogAddTimeslotHand,
    DialogAddTimeslot,
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
      currentStep: GET_TIMESLOTS_CURRENT_STEP,
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
    }),
    isManagerPage() {
      return this.$route.name === 'Manager'
    },
  },
}
</script>

<style lang="sass">
.page-manager
  padding: 20px 0
.mobileScreen
  padding: 0 16px 40px
.table-manager
  &__num_auto
    padding-left: 20px !important

  &__timeslot
    .timeslot
      display: flex
      justify-content: flex-start
      align-items: center
      font-weight: 500
      font-size: 16px
      line-height: 22px
      font-feature-settings: 'tnum' on, 'lnum' on
      color: #606266
      &0
        margin-right: 6px
      &1
        color: #DB6D39

  &__culture
    padding-left: 10px !important

  .el-table__header-wrapper
    position: relative
    z-index: 1
    border-bottom: 1px solid #DCDFE6
    border-top-left-radius: 4px
    border-top-right-radius: 4px

  .el-table__header
    min-width: 100%
  .el-table__body-wrapper.is-scrolling-left,
  .el-table__body-wrapper.is-scrolling-right,
  .el-table__body-wrapper.is-scrolling-middle
    overflow: auto !important

  .is-scrolling-none
    .el-table__body
      width: 100% !important

  .table-control
    &__icon
      .cell
        svg
          margin-left: 10px
          color: #7D90B2
          fill: transparent
          stroke: #909399
          cursor: pointer
          *
            fill: transparent
            stroke: #909399

          &:first-of-type
            margin-left: 0

    &__menu
      &[disabled="disabled"]
        color: $color-gray-primary !important
        fill: transparent !important
        stroke: $color-gray-primary !important
        cursor: not-allowed !important
        *
          fill: transparent !important
          stroke: $color-gray-primary !important

        &:hover
          transform: scale(1)

  .status
    width: 100%
    border: 1px solid #E9E9EB
    background: #ffffff
    padding: 7px 11px
    text-align: center
    display: flex
    align-items: center
    justify-content: center
    border-radius: 64px
    i
      margin-right: 5px
    &.tag-el-icon-info
      i
        color: #909399
    &.tag-el-icon-success
      background: #F0F9EB
      border: 1px solid #E1F3D8
      i
        color: #67C23A
    &.tag-el-icon-warning
      background: #FAECD8
      border: 1px solid #FAECD8
      i
        color: #E6A23C
    &.tag-el-icon-error
      background: #FEF0F0
      border: 1px solid #FDE2E2
      i
        color: #F56C6C
    &.ts-dropped,
    &.ts-deletedByAdmin,
    &.ts-moved
      text-decoration: line-through

  .table-control-label
    &.truck_status
      text-align: center

  .culture
    .culture-new-0
      text-decoration: line-through
      position: relative
      font-size: 12px

      .culture-icon
        position: absolute
        bottom: 2px
        left: 5px
        font-weight: bold

    .culture-new-1
      font-weight: 700

  .plate-status,
  .arrival_time.arrival_time,
  .table-control-label.buffer_time,
  .table-control-label.terminal_time
    text-align: center

  .table-control-label__label .el-caret
    left: 8px

  .table-control__icon-log-wrap
    div
      text-align: center

  .table-control__icon-log
    color: #7D90B2
    font-size: 20px
    cursor: pointer
    transition: transform .3s ease
    &:hover
      transform: scale(1.1)

  .table-control__icon
    display: flex
    justify-content: flex-start
    align-items: center
    .cell
      display: flex !important
      justify-content: flex-start
      align-items: center
</style>
